













































import { Component, Vue, Prop } from 'vue-property-decorator';
import MButton from '@/shared/components/MButton.vue';
import { userService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import { MyTeamUser } from '@/types/myteam';

@Component({
  components: {
    MButton,
  },
})
export default class ModalExcluir extends Vue {
  @Prop({ required: true }) modalId: string;
  @Prop({ required: true }) user: MyTeamUser;

  isLoading: boolean = false;

  mounted() {
    this.$root.$on('bv::modal::hide', (_: any) => {
      this.$emit('closeModal');
    });
  }

  async removeUser() {
    try {
      this.isLoading = true;
      await userService.delete(this.user.id);
      ToastHelper.successMessage(
        this.$t('my-account.views.my-team.messages.toast-success-remove', { user: this.user.name })
      );
      this.$emit('closeModal', { reloadUsers: true });
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('my-account.views.my-team.messages.toast-error-default'));
    } finally {
      this.isLoading = false;
    }
  }
}
